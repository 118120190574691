import React from 'react';
import Layout from 'src/components/Layout';
import SEO from 'src/components/SEO';

interface INotFoundPage {}

const NotFoundPage: React.FC<INotFoundPage> = () => (
    <Layout>
        <SEO title="404: Not found" />
        <h1>404: Not Found</h1>
        <p>You just hit a route that doesn&#39;t exist... the sadness.</p>
    </Layout>
);

export default NotFoundPage;
